import React from "react"
import styled from "styled-components"

export interface EmbedProps {
  youtubeID: string
  title: string
  aspectRatio?: Maybe<string>
  className?: string | undefined
}

interface WrapperProps {
  aspectRatio: Maybe<string>
}

export const VideoWrapper = styled.div<WrapperProps>`
  position: relative;
  overflow: hidden;
  padding-bottom: ${({ aspectRatio }) =>
    aspectRatio ? aspectRatio : "56.25"}%;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
`

const Video = styled.iframe`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
`

const Embed: React.FC<EmbedProps> = ({
  youtubeID,
  aspectRatio,
  title,
  className,
}) => (
  <VideoWrapper aspectRatio={aspectRatio} className={className}>
    <Video
      frameBorder="0"
      src={`https://www.youtube.com/embed/${youtubeID}`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title={title}
    />
  </VideoWrapper>
)

export default Embed
