import { Typography } from "@material-ui/core"
import Container from "@material-ui/core/Container"
import React from "react"
import styled from "styled-components"
import {
  PageBuilderActiviteitYoutubeFragment,
  PageBuilderErvaringYoutubeFragment,
  PageBuilderNieuwsYoutubeFragment,
  PageBuilderVacatureYoutubeFragment,
  PageBuilderYoutubeFragment,
} from "../../../__generated__/graphql-gatsby"
import Text, { TextProps } from "../text"
import Embed from "./embed"

interface YoutubeProps {
  block:
    | PageBuilderYoutubeFragment
    | PageBuilderErvaringYoutubeFragment
    | PageBuilderActiviteitYoutubeFragment
    | PageBuilderNieuwsYoutubeFragment
    | PageBuilderVacatureYoutubeFragment
}

const Wrapper = styled(Container)`
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`
const Header = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`

const TextWrapper = styled.div<{ flip: boolean; wrapLg: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: ${({ flip }) =>
    flip ? `"YOUTUBE TEXT"` : `"TEXT YOUTUBE"`};
  grid-gap: ${({ theme }) => theme.spacing(2)}px;

  @media (max-width: ${({ theme, wrapLg }) =>
      wrapLg ? theme.breakpoints.values.lg : theme.breakpoints.values.md}px) {
    grid-template-columns: 1fr;
    grid-template-areas: ${({ flip }) =>
      flip ? `"YOUTUBE" "TEXT"` : `"TEXT" "YOUTUBE"`};
  }
`

const TextBox = styled(Text)`
  grid-area: TEXT;
  display: flex;
  justify-content: center;
  align-items: center;
`

const YoutubeEmbed = styled(Embed)`
  grid-area: YOUTUBE;
  box-shadow: ${({ theme }) => theme.shadows[3]};
`

const Youtube: React.FC<YoutubeProps> = ({ block }) => {
  const noText = block.metTekst?.plaatsTekst === "none"
  const youtubeId = String(block.youtubeId)
  const title = String(block.titel)
  const showTitle = Boolean(block.toonTitel)
  const flip = block.metTekst?.plaatsTekst === "right"
  const containerWidth = block.metTekst?.breedteContainer
    ? (block.metTekst.breedteContainer as MaxWidthMuiKeys)
    : "lg"

  const textProps: TextProps = {
    block: {
      __typename: "WpPage_PageBuilder_PageBuilder_TekstEditor",
      tekst: block.tekst,
      instellingen: {
        achtergrond: block.metTekst?.achtergrond,
      },
    },
  }

  return (
    <Wrapper maxWidth={noText ? "md" : containerWidth}>
      {showTitle && (
        <Header variant="h2" align="center" color="primary">
          {title}
        </Header>
      )}

      {noText ? (
        <Embed
          youtubeID={youtubeId}
          title={title}
          aspectRatio={block.aspectRatio}
        />
      ) : (
        <TextWrapper
          flip={flip}
          wrapLg={!noText && containerWidth === "xl" ? true : false}
        >
          <TextBox block={textProps.block} />
          <YoutubeEmbed
            youtubeID={youtubeId}
            title={title}
            aspectRatio={block.aspectRatio}
          />
        </TextWrapper>
      )}
    </Wrapper>
  )
}

export default Youtube
